import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { parse } from "node-html-parser"
import "../styles/main.scss"
import SEO from "../components/seo"
import { ArticleContainer } from "../components/layout/ArticleContainer"
import styled from "styled-components"
import Img from "gatsby-image"
import ArticleAuthor from "../components/article/ArticleAuthor"
import DynamicBanner from "../components/course/DynamicBanner"
import { Skeleton } from "../components/layout/Skeleton"
import { Article } from "../components/ArticleCard"
import { Helmet } from "react-helmet"

const ArticleLead = styled.p`
  font-weight: 700;
  padding-top: 10px;
`

const ArticleBannerLink = styled.a`
  margin-top: 10px;
`

export default function Home({
  data,
}: {
  data: {
    article: Article
    file: any
    site: { siteMetadata: { siteUrl: string } }
  }
}) {
  const { article, file } = data
  const content = parse(article.content)
  const iframes = content.querySelectorAll("iframe")

  // @ts-ignore
  Array.from(iframes).forEach(iframe => {
    // @ts-ignore
    iframe.setAttribute("loading", "lazy")
  })

  const articleContent = content.toString()

  return (
    <>
      <SEO
        title={article.title}
        description={article.lead}
        image={
          article.articleImage && article.articleImage.childImageSharp.fluid.src
        }
        canonical={
          article.published_articles[article.published_articles.length - 1].url
        }
      />
      <Skeleton banner={false}>
        <ArticleContainer>
          <ArticleAuthor article={article} />
          <h1 className="title is-3">{article.title}</h1>
          <ArticleLead>{article.lead}</ArticleLead>
          {article.articleImage && (
            <Img
              fluid={article.articleImage.childImageSharp.fluid}
              alt={article.title}
            />
          )}
          <DynamicBanner />
          <div dangerouslySetInnerHTML={{ __html: articleContent }} />
          <ArticleBannerLink href="https://links.naszepierwszedziecko.pl/kurs-szkoly-rodzenia/article_banner">
            <Img
              fluid={file.childImageSharp.fluid}
              alt={"Kurs Szkoły Rodzenia z Anną Nowak-Dudą"}
            />
          </ArticleBannerLink>
        </ArticleContainer>
      </Skeleton>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    article(id: { eq: $id }) {
      lead
      content
      ...ArticleNodes
      articleImage {
        childImageSharp {
          fluid(
            maxWidth: 760
            quality: 90
            srcSetBreakpoints: [200, 400, 700]
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    file(relativePath: { eq: "baner_kurs_article.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
