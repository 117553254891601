import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Article } from "../ArticleCard"

const AuthorContainer = styled.div`
  margin-bottom: 10px;
  & p {
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    & .column {
      text-align: center;
      padding-top: 0px;
    }
  }
`

const SeeMore = styled.a`
  font-size: 1rem;
  margin-left: 10px;
`

const ExpandAuthor = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
`

const AuthorImage = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
`

const AuthorName = styled.p`
  font-size: 1.3rem;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.8);
`

const AuthorBio = styled.p`
  color: rgba(0, 0, 0, 0.44);
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 5px;
  margin-top: 5px;
`

interface Props {
  article: Article
}

export default function ArticleAuthor(props: Props) {
  const article = props.article
  const [expanded, setExpanded] = useState(false)

  return (
    <AuthorContainer>
      <div className="columns">
        {expanded && (
          <>
            <AuthorImage className="column is-narrow-tablet">
              <Img
                style={{
                  width: 90,
                  height: 90,
                  borderRadius: "50px",
                  margin: "0 auto",
                }}
                fluid={article.author.imageFile.childImageSharp.fluid}
              />
            </AuthorImage>
            <div className="column">
              <AuthorName>{article.author.name}</AuthorName>
              <AuthorBio>{article.author.biography}</AuthorBio>
            </div>
          </>
        )}
        {!expanded && (
          <ExpandAuthor>
            <AuthorName>
              Autor: <em>{article.author.name}</em>
            </AuthorName>
            <SeeMore onClick={() => setExpanded(true)} href="#">
              więcej...
            </SeeMore>
          </ExpandAuthor>
        )}
      </div>
    </AuthorContainer>
  )
}
