import React from "react"
import "../../styles/main.scss"
import styled from "styled-components"

const Container = styled.div`
  max-width: 780px !important;
`
const ArticleWrapper = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.8);
  word-break: break-word;
  font-weight: 400;
  text-align: left;

  & p {
    margin: 0px 0px 1.5rem;
  }

  & ul {
    list-style: disc;

    li {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
`

export const ArticleContainer: React.FunctionComponent<{}> = props => {
  return (
    <Container className="container">
      <ArticleWrapper>{props.children}</ArticleWrapper>
    </Container>
  )
}
