import React from "react"
import styled from "styled-components"

const BannerWrapper = styled.div`
  width: calc(100% + 30px);
  padding: 20px;
  margin: 15px -15px;
  -webkit-box-shadow: 5px 5px 15px 0px rgba(201, 197, 201, 0.9);
  -moz-box-shadow: 5px 5px 15px 0px rgba(201, 197, 201, 0.9);
  box-shadow: 5px 5px 15px 0px rgba(201, 197, 201, 0.9);
  display: flex;
  justify-content: space-between;

  @media (max-width: 540px) {
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 15px 0;
  }

  @media (max-width: 575px) {
    width: 100%;
    margin: 15px 0;
  }
`

const BannerRightContainer = styled.div`
  padding-left: 20px;
  align-items: center;
  display: flex;

  @media (max-width: 540px) {
    margin-top: 20px;
    width: 100%;
    padding-left: 0;
  }

  @media (min-width: 1200px) {
    padding: 0 30px 0 50px;
  }
`

const BannerButton = styled.a`
  color: #fffbfa;
  font-size: 1.25rem;
  background-color: #ff007d;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
  line-height: 1.5;
  text-decoration: none;

  & :active,
  :hover,
  :focus {
    color: #fffbfa;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
`

export default function DynamicBanner() {
  return (
    <BannerWrapper>
      <div>
        <div>
          <strong>Kurs Szkoły Rodzenia z Anną Nowak-Dudą</strong>
        </div>
        <div>
          <small>
            Dołącz do 1323 zadowolonych Mam. Pokonaj strach i niewiedzę. Poczuj
            się pewna i przygotowana. Gwarancja satysfakcji!
          </small>
        </div>
      </div>
      <BannerRightContainer>
        <BannerButton
          href="https://links.naszepierwszedziecko.pl/kurs-szkoly-rodzenia/article_dynamic_banner"
          className="button"
        >
          Dołącz teraz!
        </BannerButton>
      </BannerRightContainer>
    </BannerWrapper>
  )
}
